import React from "react";
import { SEO } from "gatsby-plugin-seo";

const MetaConfig = ({ metaData: { title, description, titlefr, descriptionfr }, path }) => {
	return (
		<SEO
			title={path = "/en/" ? title : titlefr}
			description={path = "/en/" ? description : descriptionfr}
			pagePath={path}
			schema={`{
                "@context": "http://schema.org",
                "@type": "WebPage",
                "mainEntity": {
                    "@type": "Organization",
                    "name": "CROWLR Benchmarking",
                    "image": "https://www.crowlr.com/img/logo.png"
                }
            }`}
		/>
	);
};

export default MetaConfig;
