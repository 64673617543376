import React from "react";
import { anonymousCandidacy } from "../seo-meta-data";
import MetaConfig from "../hooks/MetaConfig";
import Layout from "../components/App/Layout";
import NavbarThree from "../components/App/NavbarThree";
import Footer from "../components/App/Footer";
import SD1preq from "../components/ServiceDetails/Sd1preq";
import RelatedServices from "../components/ServiceDetails/RelatedServices";
import ProjectStartArea from "../components/ServiceDetails/ProjectStartArea";
const Details = ({ location }) => {
	return (
		<Layout path={location.pathname}>
			<MetaConfig
				metaData={anonymousCandidacy}
				path={location.pathname}
			/>
			<NavbarThree />
			<SD1preq />
			<RelatedServices />
			<ProjectStartArea />
			<Footer />

		</Layout>
	);
};

export default Details;

export const query = graphql`
	query($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
