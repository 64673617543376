import React from "react";
import ServiceSidebar from "./ServiceSidebar";
import project4 from "../../assets/images/projects/project4.jpg";
import { useTranslation } from "gatsby-plugin-react-i18next";

const PRE = () => {
	const { t } = useTranslation();

	return (
		<section className="services-details-area pt-100px ptb-100">
			<div className="container">
				<div className="row">
					<div className="col-lg-8 col-md-12">
						<div className="services-details-desc">
							<span className="sub-title">
								<h2>{t("home.PREh2")}</h2>
							</span>
							<h3>{t("home.PREh31")}</h3>
							<p>{t("home.PREp1")}</p>

							<div className="row align-items-center">
								<div className="col-lg-6 col-md-6">
									<div className="image">
										<img src={project4} alt="about" />
									</div>
								</div>

								<div className="col-lg-6 col-md-6">
									<h3>{t("home.PREh32")}</h3>
									<p>{t("home.PREp2")}</p>
									<p>{t("home.PREp3")}</p>
									<p>{t("home.PREp4")}</p>

									<div className="content">
										<h3>{t("home.PREh34")}</h3>
										<li>{t("home.PREi1")}</li>
										<li>{t("home.PREi2")}</li>
										<li>{t("home.PREi3")}</li>
									</div>
								</div>
							</div>
							{/*
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        xx
                                    </div>
                                </div>
                                                          <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        xx
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        xx
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
xx                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        xx
                                    </div>
                                </div>
                            </div>
*/}
						</div>
					</div>

					<div className="col-lg-4 col-md-12">
						<ServiceSidebar />
					</div>
				</div>
			</div>
		</section>
	);
};

export default PRE;
