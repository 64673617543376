/*
 ** @route    https://www.crowlr.com
 ** @desc     Homepage Meta Data
 ** @access   Public
 */
export const homepage = {
	title: "AI Based Recruting System",
	description:
		"CROWLR Benchmarking separates the top performers in every industry from the rest. The real star performers set targets that are 'stretch' goals. Do that and you will perform better than most.",

	titlefr: "AI Based Recruting System",
	descriptionfr:
		"CROWLR Benchmarking separates the top performers in every industry from the rest. The real star performers set targets that are 'stretch' goals. Do that and you will perform better than most.",


};

/*
 ** @route    https://www.crowlr.com/en/talent-management-system
 ** @desc     Talent Management System
 ** @access   Public
 */
export const companyBranding = {
	title: "Company Branding & Candidate Experience",
	description:
		"How to offer differentiating and innovative value for candidates ? Candidates profiles pre-qualification, without cognitive biases !",

	titlefr: "Campany Branding et Expérience Candidat! Personal Branding et Profil Pro Anonyme!",
	descriptionfr:
		"Comment limiter les biais cognitifs avec votre profil professionnel? Comment faire son autopromotion et postuler autrement?",

};

/*
 ** @route    https://www.crowlr.com/en/recruiting-system
 ** @desc     Candidate Experience
 ** @access   Public
 */
export const companyExperience = {
	title: "Recruitment Software | Talent Recruiting Management",
	description:
		"Showcases your employer brand forward with innovative positive recruitment!",

	titlefr: "EXPÉRIENCE CANDIDAT",
	descriptionfr:
		"Mettez votre marque en avant avec un recrutement innovant positif!",
};

/*
 ** @route    https://www.crowlr.com/en/employee-profile-branding-system
 ** @desc     Employee Profile Branding System
 ** @access   Public
 */
export const personalBranding = {
	title: "Employee Branding System | Enhance Your Job Profile",
	description:
		"Enhance your image and your notoriety to support your job searches and succeed in your career development.",

	titlefr:
		"Personal Branding | Autopromotion et postuler autrement",
	descriptionfr:
		"Valoriser votre image et votre notoriété pour accompagner vos recherches d'emploi et réussir votre évolution de carrière.",
};

/*
 ** @route    https://www.crowlr.com/en/hr-recruitment-solution
 ** @desc     HR Recruitment Solutions
 ** @access   Public
 */
export const recruitment = {
	title: "Recrutement, Fast System for Hiring Process | CV Management",
	description: "Recruiting and Career management software",

	titlefr: "Recrutement",
	descriptionfr:
		"Plateforme de recrutement rapide et facile qui améliore la qualité des candidatures. Solution RH pour attirer les candidats rapidement.",
};

/*
 ** @route    https://www.crowlr.com/en/talent-acquisition-strategy
 ** @desc     Talent Acquisition Strategy
 ** @access   Public
 */
export const acquisitionStrategy = {
	title: "Dynamic Benchmarking System for Talent Acquisition",
	description: "Dynamic Benchmark Widget to enrich career pages.",

	titlefr: "STRATÉGIES POUR ATTIRER ET RECRUTER LES CANDIDATS",
	descriptionfr:
		"Un Benchmark Dynamique pour compléter et enrichir votre communication digitale. AMELIORER L'EFFICACITE DE VOS OFFRES D'EMPLOI",
};

/*
 ** @route    https://www.crowlr.com/en/employee-reference-solutions
 ** @desc     Employee Reference Solutions
 ** @access   Public
 */
export const referenceSolutions = {
	title: "References & Hierarchical Management System",
	description:
		"Hierarchical relationships between users are normed with CROWLR. This is what makes candidates references check possibles. We only are deploying non-intrusive reference check referral.",

	titlefr: "LES RÉFÉRENCES DE « PAIR À PAIR »",
	descriptionfr:
		"Définition des responssabilités entre pairs!\n" +
		"RELATIONS hierarchiques entre utilisateurs NORMEES. La prise de Références non intrusive. La cooptation de la prise de références.",
};

/*
 ** @route    https://www.crowlr.com/en/profile-social-sharing
 ** @desc     Profile Social Sharing
 ** @access   Public
 */
export const profileSocialSharing = {
	title: "Promote Your Profile On Social Media | HRMS",
	description:
		"To get the attention of Line managers or Recruiters with a BOOST to your LinkedIn profile. Start now with a creative approach.",

	titlefr: "Promouvoir son profil professionnel sur les réseau sociaux.",
	descriptionfr:
		"Pour obtenir l'attention des Line Managers ou des Recruteurs via un BOOST Crowlr sur votre profil LinkedIn. Lancez vous dès maintenant avec une approche créative",
};

/*
 ** @route    https://www.crowlr.com/en/cooptation-recruitment-solutions
 ** @desc     Cooptation Recruitment Solutions
 ** @access   Public
 */
export const recruitmentSolutions = {
	title: "Employee referral recruitment",
	description: "Employee referral recruitment",

	titlefr: "Le Recrutement par Cooptation. Comment attirer des Talents.",
	descriptionfr:
		"Recruter en mode Cooptation consiste à solliciter une communauté de « coopteurs ». Pour ajouter du crédit à leurs profils. Le parrainage digital d'un professionnel passif ou actif est une méthode qui croise anonymement les informations des divers profils lors du Benchmark entre utilisateurs.",
};

/*
 ** @route    https://www.crowlr.com/en/services
 ** @desc     Services
 ** @access   Public
 */
export const services = {
	title: "AI Based Recruting System",
	description: "Employer Brand and Innovative Recruitment",

	titlefr: "Principaux services applicatifs d'AI de Crowlr",
	descriptionfr:
		"Le widget Javascript vous permettra d'afficher facilement le BENCHMARK CROWLR sur, votre site web. Veuillez contacter notre équipe si vous souhaitez afficher un formulaire personnalisé pour y inclure des questions de préqualification.",
};

/*
 ** @route    https://www.crowlr.com/en/mobility-and-decision-support-solutions
 ** @desc     Mobility and decision support solutions
 ** @access   Public
 */
export const decisionSupportSolutions = {
	title: "Recruitment and Internal Mobility",
	description:
		"CROWLR develop a culture of internal mobility that translates, skills equal to the consideration of internal candidates.",

	titlefr:
		"Recrutement et Mobilité Interne",
	descriptionfr:
		"Pour une ouverture de poste, développer une culture de la mobilité interne ce traduit, à compétences égales par la considération des candidatures internes. Comparer en amont les profils disponibles de votre ATS sur la base de compétences transversales et transferables.",
};

/*
 ** @route    https://www.crowlr.com/en/interactive-job-candidacy-and-employability
 ** @desc     Interactive job candidacy and employability
 ** @access   Public
 */
export const Interactive = {
	title: "Interactive Benchmark, Employability",
	description: "Decision Support Application upstream of candidacy",

	titlefr:
		"Benchmark Interactif, Employabilité",
	descriptionfr:
		"Aide à la décision avant Candidature",
};

/*
 ** @route    https://www.crowlr.com/en/selfpromotion-and-talent-community
 ** @desc     Selfpromotion and talent community
 ** @access   Public
 */
export const talentCommunity = {
	title: "Selfpromotion and talent community",
	description: "Promoting its own Professional Profile",

	titlefr:
		"Visibilité et Autopromotion",
	descriptionfr:
		"Promotion de son Profil Professionnel",
};

/*
 ** @route    https://www.crowlr.com/en/prequalification-rgpd-and-anonymous-candidacy
 ** @desc     Prequalification rgpd and anonymous candidacy
 ** @access   Public
 */
export const anonymousCandidacy = {
	title: "Prequalification rgpd and anonymous candidacy",
	description: "Candidates applications processing and GDPR",

	titlefr:
		"Pré-qualification RGPD et candidature anonyme",
	descriptionfr:
		"Valoriser votre marque employeur, dans le respect de vos engagements RSE avec une pré-sélection exempte de biais cognitifs",
};

/*
 ** @route    https://www.crowlr.com/en/about-us
 ** @desc     About us
 ** @access   Public
 */
export const aboutUs = {
	title: "About Us",
	description:
		"A unique opportunity to an enhanced candidates and employees mobility experience. The starting point to various services we are launching as a limited Private Beta release over Q3 2021.",

	titlefr:
		"A propos",
	descriptionfr:
		"Mettre en place une expérience utilisateur unique, c'est le point de départ des divers services que nous mettons à dispositions des entreprises et des individus.",
};

/*
 ** @route    https://www.crowlr.com/en/contact
 ** @desc     Contact
 ** @access   Public
 */
export const contactUs = {
	title: "Contact",
	description:
		"Get Inspired! Free demo access to one of our solution for talent acquisition professionals.",

	titlefr:
		"Nous contacter",
	descriptionfr:
		"Vous souhaitez obtenir une démonstration pour l'une des solutions ?",
};

/*
 ** @route    https://www.crowlr.com/en/hrm-eco-system
 ** @desc     HRM eco system
 ** @access   Public
 */
export const ecosysteme = {
	title:
		"VOS PARTENAIRES POUR DÉCROCHER VOTRE PROCHAIN POSTE",
	description:
		"Bénéficier de nos expériences métier et de notre connaissance du processus de recrutement.",

	titlefr:
		"VOS PARTENAIRES POUR DÉCROCHER VOTRE PROCHAIN POSTE",
	descriptionfr:
		"Bénéficier de nos expériences métier et de notre connaissance du processus de recrutement",
};
